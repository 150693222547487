import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import moment from "moment";
import { manifestService } from "_services";
import { ViewManifest } from "./ViewManifest";
import { toast } from "react-toastify";

const DATE_FORMAT = "MMMM DD, YYYY, h:mm A";

const ManifestVersion = (props) => {
  const { id, manifestId } = props.match.params;

  const pdfContainer = useRef(null);

  const [url, setURL] = useState("");
  const [numPages, setNumPages] = useState(1);
  const [width, setWidth] = useState(998);
  const [historyList, setHistoryList] = useState([]);
  const [active, setActive] = useState(null);
  const [hideVersionNavbar, setHideVersionNavbar] = useState(false);

  useEffect(() => {
    const pdfWidth = pdfContainer?.current?.clientWidth;
    setWidth(pdfWidth);
    const formData = new FormData();
    manifestService
      .getVersions(manifestId)
      .then((res) => {
        if (res.success) {
          setHistoryList(res?.data);
          setActive(res?.data[0]);
        }
      })
      .catch(() => {
        toast.error("Failed to fetch manifest versions.");
      });
  }, []);

  const onClickHistory = (hist, index) => {
    setActive(hist);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const hideNavbar = () => {
    setHideVersionNavbar(!hideVersionNavbar);
  };

  return (
    <div>
      <div className="dashboard_container">
        <div className="white">
          <div className="back_to_board dashboard_expiration_date_nav">
            <div className="back_button">
              <img
                src={require("../assets/images/back.svg").default}
                alt="back"
              />{" "}
              <span>
                <Link to={`/resources/${id}`}>Back to Resource</Link>
              </span>
            </div>
            <a class="hide_nav_link btn" onClick={hideNavbar}>
              {hideVersionNavbar ? "Display" : "Hide"} Version Navbar
            </a>
          </div>
        </div>
        <section className="user-docs res_pdf_container">
          <div className="onboarding-docs  sidebar-form">
            <section className="d-flex w-100">
              <div className="doc_info">
                <div
                  className="doc_info-lh doc-list-lh"
                  style={{ display: hideVersionNavbar ? "none" : "" }}
                >
                  <div className="doc-title">
                    <h3>Version History </h3>
                  </div>
                  <div className="doc-statusbar doc-listing">
                    <ul className="doc-lists">
                      {historyList.map((hist, index) => (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            onClickHistory(hist, index);
                          }}
                          href="/"
                        >
                          <li
                            className={`${
                              active?.id === hist.id
                                ? "doc-active hist_list"
                                : "hist_list"
                            }`}
                          >
                            <p>
                              <b className="active_green_text">
                                {moment
                                  .unix(hist.added_date)
                                  .format(DATE_FORMAT)}
                              </b>
                            </p>
                            {hist?.created_by_user && (
                              <p>{hist?.created_by_user}</p>
                            )}
                            {hist?.latest_version == 1 && (
                              <p>
                                <i>Current version</i>
                              </p>
                            )}
                          </li>
                        </a>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  style={{ width: hideVersionNavbar ? "100%" : "" }}
                  className={`doc_info-rh fs-manifest-wrapper`}
                  ref={pdfContainer}
                >
                  {/* Document */}
                  <div className="doc-wrapper doc-wrapper-rs">
                    <ViewManifest
                      key={active?.id} // Key prop to force re-render on active.id change
                      resourceDetails={props}
                      resource_id={id}
                      manifest_id={manifestId}
                      manifest_version_id={active?.id}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ManifestVersion;
