import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import deleteIcon from "../assets/icons/delete-btn.svg";
import emailICons from "../assets/icons/resource-email-icon.svg";
import editIcon from "../assets/icons/doc-edit.svg";
import historyIcon from "../assets/icons/history.svg";
import {
  isResourceOrderFormsLoading,
  getCurrentUserInfo,
} from "_helpers/reduxSelector";
import { config } from "_helpers";
import { manifestService } from "_services";
import { toast } from "react-toastify";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import CreatableAsyncSelect from "_components/utils/CreatableAsyncSelect/CreatableAsyncSelect";
import { resourceActions } from "_actions";
import AsyncSelect from "react-select/async";
import styles from "./resource-order-form.scss";

const ROF_TYPE = {
  CREATED: 1,
  UPLOADED: 2,
};

const ResourceOrderFormList = ({
  getResourceOrderForms,
  data,
  lastPage,
  redirectToCreatedForm,
  isCreatedForm,
  handleEditRof,
  setSelectedEmployees,
  selectedEmployees,
}) => {
  const [page, setPage] = React.useState(0);
  const isLoading = useSelector((state) => isResourceOrderFormsLoading(state));
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [editROModal, setEditROModal] = React.useState(false);
  const [selectedUploadedROFile, setSelectedUploadedROFile] = React.useState();
  const [formSubmission, setFormSubmission] = React.useState(false);
  const [selectedResource, setSelectedResource] = React.useState();
  const [copySelectedResource, setCopySelectedResource] = React.useState();

  const currentUserInfo = useSelector(getCurrentUserInfo);

  const dispatch = useDispatch();

  const [emailPopup, setEmailPopup] = React.useState();

  const handlePageChange = (newPage) => {
    getResourceOrderForms(newPage?.selected + 1);
    setPage(newPage?.selected);
  };

  const handleChooseResources = async (text) => {
    let data = new FormData();
    data.append("keyword", text);
    const response = await dispatch(resourceActions.searchResource(data));
    let resourceList = [];
    const items = response.data;
    var res = [];
    for (var key in items) {
      var arr = items[key];
      if (arr.length) {
        res = res.concat(arr);
      }
    }
    if (res.length > 0) {
      res.forEach((item) => {
        let resource = {
          label: item.resource_name,
          value: item.id,
        };
        resourceList.push(resource);
      });
    }
    return resourceList;
  };

  const showEditRoPopup = (item) => {
    setSelectedUploadedROFile(item);
    const selectedResource = item?.resources.map((resource) => ({
      value: resource.id,
      label: resource.resource_name,
    }));
    setSelectedResource(selectedResource);
    setCopySelectedResource(selectedResource);
    setEditROModal(true);
  };

  const handleDeleteRof = ({ id, type }) => {
    let formData = new FormData();
    formData.append("rof_id", id);
    formData.append("rof_type", type);
    manifestService
      ?.deleteResourceOrderPermanently(formData)
      .then(({ success, message }) => {
        if (success) {
          toast.success(message);
          getResourceOrderForms(page + 1);
        } else {
          toast.error(message);
        }
        setDeleteConfirmModal(false);
      })
      .catch(() => {
        setDeleteConfirmModal(false);
      });
  };

  const loadCreatedFormActions = (item) => {
    return (
      <React.Fragment>
        <td>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => redirectToCreatedForm?.(item?.id)}
          >
            <img
              src={`${config?.assetUrl}icons/doc-admin-view.svg`}
              alt="view document"
            />
          </span>
        </td>
        <td>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleEditRof(item?.id)}
          >
            <img src={editIcon} alt="edit document" />
          </span>
        </td>

        <td className="resource-order-email-icon" style={{ minWidth: "auto" }}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => onEmailClick(item)}
          >
            <img src={emailICons} alt="email doc" />
          </span>
        </td>

        <td style={{ minWidth: "auto" }}>
          <Link to={`/resource-history/${item?.id}`}>
            <span style={{ cursor: "pointer" }}>
              <img src={historyIcon} alt="edit document" />
            </span>
          </Link>
        </td>
        <td>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              onDeleteClick(item, ROF_TYPE?.CREATED);
            }}
          >
            <img
              src={deleteIcon}
              alt="delete document"
              style={{ width: "15px" }}
            />
          </span>
        </td>
      </React.Fragment>
    );
  };

  const onEmailClick = (item) => {
    setSelectedUploadedROFile(item);
    setEmailPopup(true);
  };

  const onDeleteClick = (item, type) => {
    setSelectedUploadedROFile({
      id: item?.id,
      type: type,
    });
    setDeleteConfirmModal(true);
  };

  const handleCancelEmailClick = () => {
    setSelectedEmployees([]);
    setEmailPopup(false);
  };

  const MENU_WIDTH = 300;

  const selectStyles = {
    control: (css) => ({
      ...css,
      width: MENU_WIDTH || "auto",
      opacity: MENU_WIDTH ? 1 : 0,
      zIndex: 9999,
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      opacity: MENU_WIDTH ? 1 : 0,
      zIndex: 9999,
    }),
    option: (css) => ({ ...css, paddingRight: 36 + 8, zIndex: 9999 }),
  };

  const handleEditRO = async () => {
    setFormSubmission(true);
    const data = new FormData();
    selectedResource.forEach((resource) => {
      data.append("resource_ids[]", resource.value);
    });

    dispatch(
      resourceActions.updateROResources(selectedUploadedROFile?.id, data)
    )
      .then((response) => {
        if (response.success) {
          getResourceOrderForms(page + 1);
          toast.success(
            response.message || "Resource order updated successfully."
          );
          setEditROModal(false);
          setSelectedResource();
        } else {
          setSelectedResource(copySelectedResource);
          toast.error(
            "Failed to update RO - " + response.message ||
              "An error occurred while updating the resource order."
          );
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    setFormSubmission(false);
  };

  const handleSendEmail = async () => {
    setFormSubmission(true);
    const data = new FormData();
    data.append(
      "file_hash",
      isCreatedForm ? "" : selectedUploadedROFile.file_hash
    );
    selectedEmployees?.forEach((employee, index) => {
      data.append(`email[${index}]`, employee.email);
    });
    data.append(
      "resource_order_id",
      isCreatedForm ? selectedUploadedROFile.id : ""
    );
    data.append(
      "file_name",
      selectedUploadedROFile?.file_name || selectedUploadedROFile?.project_name
    );
    data.append("sender_name", currentUserInfo?.full_name);

    const response = await dispatch(
      resourceActions.resourceOrderEmailEmployees(data)
    );

    if (response.success) {
      toast.success(response.message);
      handleCancelEmailClick();
    } else {
      toast.error(response.message);
    }
    setFormSubmission(false);
  };

  const onClickRemoveEmployeeFromMailList = (data) => {
    setSelectedEmployees(
      selectedEmployees.filter((item) => item.label !== data.label)
    );
  };

  const loadActions = (item) => {
    return (
      <React.Fragment>
        <td width="40" className="resource-order-cta-icon">
          <a
            href={`${config?.s3BucketUrl}${item?.file_hash}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={`${config?.assetUrl}icons/doc-admin-view.svg`} alt="" />
          </a>
        </td>

        <td>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => showEditRoPopup(item)}
          >
            <img src={editIcon} alt="edit document" />
          </span>
        </td>

        <td width="40" className="resource-order-email-icon">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => onEmailClick(item)}
          >
            <img src={emailICons} alt="email doc" />
          </span>
        </td>

        <td width="40" className="resource-order-delete-icon">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              onDeleteClick(item, ROF_TYPE?.UPLOADED);
            }}
          >
            <img
              src={deleteIcon}
              alt="delete document"
              style={{ width: "15px" }}
            />
          </span>
        </td>
      </React.Fragment>
    );
  };

  const EditRoPopup = () => (
    <ReactModal isOpen={editROModal} onClose={() => setEditROModal(false)}>
      <div id="statusPopup" className="popupModal">
        <div className="modal-head">
          <h4 className="modal-title">Edit Uploaded Resource Order</h4>
        </div>
        <div className="modal-body">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h5>File: {selectedUploadedROFile?.file_name}</h5>
            <div className={styles.crew_select_card}>
              <label>Choose Resources</label>
              <AsyncSelect
                cacheOptions
                loadOptions={handleChooseResources}
                onChange={(selection) => setSelectedResource(selection)}
                isMulti
                defaultOptions
                styles={selectStyles}
                value={selectedResource}
              />
            </div>
            <div className="resource_order_form_delete_modal_action">
              <button
                className="button grey"
                onClick={() => setEditROModal(false)}
              >
                Cancel
              </button>
              <button
                name="save"
                className={`button ${!selectedResource?.length ? "grey" : ""}`}
                onClick={() => handleEditRO(selectedUploadedROFile)}
                disabled={!selectedResource?.length || formSubmission}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );

  const ConfirmationPopup = () => (
    <ReactModal
      isOpen={deleteConfirmModal}
      onClose={() => setDeleteConfirmModal(false)}
    >
      <div id="statusPopup" className="popupModal">
        <div className="modal-head">
          <h4 className="modal-title">Confirm Action</h4>
        </div>
        <div className="modal-body">
          <p>
            {
              "Deleting RO will remove all the details from corresponding manifests, are you sure want to continue?"
            }
          </p>
          <div className="resource_order_form_delete_modal_action">
            <button
              className="button grey"
              onClick={() => setDeleteConfirmModal(false)}
            >
              Cancel
            </button>
            <button
              name="save"
              className="button"
              onClick={() => handleDeleteRof(selectedUploadedROFile)}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );

  const EmailPopup = () => (
    <ReactModal isOpen={emailPopup} onClose={() => setEmailPopup(false)}>
      <div id="statusPopup" className="popupModal">
        <div className="modal-head ro-email-modal-head">
          <h4 className="modal-title">
            Email "
            {selectedUploadedROFile?.file_name ||
              selectedUploadedROFile?.project_name}
            "
          </h4>
        </div>
        <div className="modal-body ro-modal-body">
          <CreatableAsyncSelect
            setSelectedEmployees={setSelectedEmployees}
            selectedEmployees={selectedEmployees}
          />
          {!!selectedEmployees.length && (
            <div className="mail-maifest-wrapper">
              <div className="mail-manifest wrapper">
                <div className="head">
                  <div
                    className="name ro-email-modal-name"
                    style={{ fontWeight: "bold" }}
                  >
                    Name
                  </div>
                  <div className="email" style={{ fontWeight: "bold" }}>
                    Email
                  </div>
                </div>
                {selectedEmployees?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="employee employee_item selected-employee"
                    >
                      <div className="pic image-holder ro-profile-pic-wrapper">
                        {item.profilePic ? (
                          <img
                            src={config.apiUrl + "/" + item.profilePic}
                            onError={(e) =>
                              (e.target.src = `${
                                config.apiUrl + "/" + config.altImg
                              }`)
                            }
                            alt="prof-pic"
                          />
                        ) : item.name ? (
                          <img
                            src={config.apiUrl + "/" + config.altImg}
                            alt="prof-pic"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="name">
                        <span className="">{item.name}</span>
                      </div>
                      <div className="email">{item.email}</div>
                      <div className="close-btn">
                        <img
                          alt="close"
                          src={
                            require("../assets/images/close_button.png").default
                          }
                          onClick={() =>
                            onClickRemoveEmployeeFromMailList(item)
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="resource_order_form_delete_modal_action">
            <button className="button grey" onClick={handleCancelEmailClick}>
              Cancel
            </button>
            <button
              name="save"
              className="button"
              onClick={handleSendEmail}
              disabled={!selectedEmployees.length || formSubmission}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );

  const noDataAvailable = !isLoading && !data?.length;

  return (
    <div className="history_lists outer_list">
      <div className="history_lists_wap">
        <table className="mobile_hide report_list_width_full">
          <tr className="report_list_table_row">
            <th>Name</th>
            <th>Resources</th>
            {isCreatedForm && <th>Project #</th>}
            <th></th>
          </tr>
          <tbody className="report_list_table_tbody">
            {noDataAvailable ? (
              <tr>
                <td>
                  <div className="dashboard_expiration_date_no_data">
                    No Data Found
                  </div>
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={index} className="resource-order-item">
                  <td
                    className="resource-order-item-title"
                    style={{
                      width: isCreatedForm ? "30%" : "40%",
                      wordBreak: "break-all",
                    }}
                  >
                    {item?.file_name ?? item?.project_name ?? "-"}
                  </td>
                  <td>
                    {item?.resources
                      ?.map((resource) => resource.resource_name)
                      .join(", ")}
                  </td>
                  {isCreatedForm && (
                    <td style={{ width: "25%", wordBreak: "break-all" }}>
                      {item?.project_no ? item.project_no : "-"}
                    </td>
                  )}
                  <td style={{ width: "25%" }}>
                    {isCreatedForm
                      ? loadCreatedFormActions(item)
                      : loadActions(item)}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {noDataAvailable ? (
        <div className="dashboard_expiration_date_no_data for_mobile_history_list">
          No Data Found
        </div>
      ) : (
        <ResourceOrderFormListMobileView
          isCreatedForm={isCreatedForm}
          data={data}
          redirectToCreatedForm={redirectToCreatedForm}
          onEmailClick={onEmailClick}
          onDeleteClick={onDeleteClick}
          handleEditRof={handleEditRof}
          handleEditResource={showEditRoPopup}
        />
      )}

      {!isLoading && data?.length ? (
        <div
          className="fs-pagination-wrapper-outer ro-pagination-wrapper"
          key={7}
        >
          <div className="customised-pagination right-align">
            <ReactPaginate
              forcePage={page}
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={lastPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => handlePageChange(data)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
      <ConfirmationPopup />
      <EmailPopup />
      <EditRoPopup />
    </div>
  );
};

export default ResourceOrderFormList;

const ResourceOrderFormListMobileView = ({
  data,
  isCreatedForm,
  redirectToCreatedForm,
  onEmailClick,
  onDeleteClick,
  handleEditRof,
  handleEditResource,
}) => {
  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list">
        <hr></hr>
        <div className="history_items resource_order_form_mobile">
          <div className="status_item">
            <div className="list_item">
              <b>Name</b>
            </div>
            <div className="list_item">
              {item?.file_name ?? item?.project_name ?? "-"}
            </div>
            <div className="list_item">
              <b>Resource</b>
            </div>
            <div className="list_item">
              {item?.resources
                ?.map((resource) => resource.resource_name)
                .join(", ")}
            </div>

            {isCreatedForm ? (
              <>
                <div className="list_item">
                  <b>Project #</b>
                </div>
                <div className="list_item">
                  {item?.project_no ? item?.project_no : "-"}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="status_item">
            {isCreatedForm ? (
              <>
                <div className="list_item">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => redirectToCreatedForm?.(item?.id)}
                  >
                    <img
                      src={`${config?.assetUrl}icons/doc-admin-view.svg`}
                      alt=""
                    />
                  </span>
                </div>
                <div className="list_item">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditRof(item?.id)}
                  >
                    <img src={editIcon} alt="edit document" />
                  </span>
                </div>
                <div className="list_item">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => onEmailClick(item)}
                  >
                    <img src={emailICons} alt="email doc" />
                  </span>
                </div>
                <div className="list_item">
                  <Link to={`/resource-history/${item?.id}`}>
                    <span style={{ cursor: "pointer" }}>
                      <img src={historyIcon} alt="edit document" />
                    </span>
                  </Link>
                </div>
                <div className="list_item">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onDeleteClick(item, ROF_TYPE?.CREATED);
                    }}
                  >
                    <img
                      src={deleteIcon}
                      alt="delete document"
                      style={{ width: "15px" }}
                    />
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="list_item">
                  <a
                    href={`${config?.s3BucketUrl}${item?.file_hash}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={`${config?.assetUrl}icons/doc-admin-view.svg`}
                      alt=""
                    />
                  </a>
                </div>
                <div className="list_item">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditResource(item)}
                  >
                    <img src={editIcon} alt="edit document" />
                  </span>
                </div>
                <div className="list_item">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => onEmailClick(item)}
                  >
                    <img src={emailICons} alt="email doc" />
                  </span>
                </div>
                <div className="list_item">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onDeleteClick(item, ROF_TYPE?.UPLOADED);
                    }}
                  >
                    <img
                      src={deleteIcon}
                      alt="delete document"
                      style={{ width: "15px" }}
                    />
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};
