import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import ReactModal from "react-modal";
import { dashboardActions, createProfileActions } from "_actions";
import { selectEmployeesFireHistoryReportLoading } from "_helpers/reduxSelector";
import { toast } from "react-toastify";
import {
  formatDate,
  handleErrorResponse,
  preventNegativeValueInDocs,
} from "_helpers/helpers";
import deleteIcon from "../assets/icons/delete-btn.svg";
import moment from "moment";
import DatePicker from "react-datepicker";
import Modal from "_components/utils/Modal/Modal";
import { selectCurrentUserPermissions } from "_helpers/reduxSelector";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  assignmentTypeClass: ASC_CLASS,
  incidentNameClass: ASC_CLASS,
  locationClass: ASC_CLASS,
  fireSizeClass: ASC_CLASS,
  complexityClass: ASC_CLASS,
  fuelTypeClass: ASC_CLASS,
  dateOnClass: ASC_CLASS,
  dateOffClass: ASC_CLASS,
};

const datePickerPopperModifiers = [
  {
    name: "offset",
    options: {
      offset: [5, 10],
    },
  },
  {
    name: "customStyle",
    enabled: true,
    fn: (data) => {
      data.styles.width = "300px";
      return data;
    },
  },
];

const API_req_date_format = "YYYY-MM-DD";

const FireHistoryReportWithEmployees = ({
  getFireHistoryByHash,
  fireHistoryReport,
  handleDeleteItem,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectEmployeesFireHistoryReportLoading);
  const permList = useSelector(selectCurrentUserPermissions);
  const data = fireHistoryReport?.data ?? [];
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [order, setOrder] = useState(false); /** true === asc **/
  const [sortItem, setSortItem] = useState("type");
  const [page, setPage] = useState(0);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [selectedHistoryItem, setSelectedHistoryItem] = useState({
    id: "",
    name: "",
    assignmentType: "",
    fuelType: null,
    fireSize: null,
    complexity: null,
    shifts: "",
    hotline: "",
    startDate: "",
    endDate: "",
    incident: "",
    location: "",
  });

  const [sortArrowStyle, setStyleState] = useState(initialStyleState);
  const {
    assignmentTypeClass,
    incidentName,
    locationClass,
    fireSizeClass,
    fuelTypeClass,
    dateOffClass,
    dateOnClass,
    complexityClass,
  } = sortArrowStyle;

  const handleOnWheel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.blur();
  };

  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    getFireHistoryByHash(newPage?.selected + 1, sortItem || "type", sortOrder);
    setPage(newPage?.selected + 1);
  };

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  const onClickSelectHistoryItem = (selected) => {
    setSelectedHistoryItem({
      id: selected?.assignment_history_user_id,
      name: selected?.first_name + " " + selected?.last_name,
      assignmentType: selected?.assignment_type,
      fireSize: selected?.fire_size,
      fuelType: selected?.fuel_type,
      complexity: selected?.complexity,
      shifts: selected?.shifts,
      hotline: selected?.hotline_shifts,
      incident: selected?.incident_name || null,
      location: selected?.location || null,
      startDate: moment(selected?.date_on).isValid()
        ? moment(selected?.date_on)
        : "",
      endDate: moment(selected?.date_off).isValid()
        ? moment(selected?.date_off)
        : "",
    });
    setEditModalOpen(true);
  };

  const onChangeEditHistoryItem = (name, value) => {
    setSelectedHistoryItem((prevState) => {
      const newHotline = name === "hotline" ? value : prevState?.hotline;
      return {
        ...prevState,
        hotline: newHotline,
      };
    });
  };

  const updateHistoryItem = async (e) => {
    e.preventDefault();
    if (!selectedHistoryItem?.hotline) {
      return toast.error("Hotline Shifts is a required field.");
    }
    const reqData = new FormData();
    reqData.append("hotline_shifts", selectedHistoryItem?.hotline || "");

    const res = await dispatch(
      dashboardActions.updateFireHistoryHotlineShifts(
        selectedHistoryItem?.id,
        reqData
      )
    );
    if (res.success) {
      toast.success("Hotline Shifts updated successfully");
      setEditModalOpen(false);
      fetchFireHistory();
    } else {
      handleErrorResponse(res);
    }
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedHistoryItem({
      id: "",
      name: "",
      assignmentType: "",
      fuelType: null,
      fireSize: null,
      complexity: null,
      qual: null,
      shifts: "",
      hotline: "",
      startDate: "",
      endDate: "",
      incident: "",
      location: "",
    });
  };

  const fetchFireHistory = () => {
    const sortOrder = order ? "asc" : "desc";
    getFireHistoryByHash(page, sortItem, sortOrder);
  };

  useEffect(() => {
    if (sortItem) {
      fetchFireHistory();
    }
    dispatch(createProfileActions.getAll());
  }, [sortItem, order]);

  return (
    <div className="history_lists outer_list">
      <Modal
        isOpen={editModalOpen}
        className="update-applicant-status-modal incident-center"
      >
        <div className="modal-head">
          <h4>EDIT FIRE HISTORY</h4>
        </div>
        <form onSubmit={updateHistoryItem}>
          <div className="modal-body applicant-status-modal-body scroll-incident">
            <div className="modify-item">
              <p>Employee Name</p>
              <input
                name="assignmentType"
                value={selectedHistoryItem?.name}
                disabled
              />
            </div>
            <div className="modify-item">
              <p>Incident Name</p>
              <input
                name="incident"
                value={selectedHistoryItem?.incident}
                disabled
              />
            </div>
            <div className="modify-item">
              <p>Assignment Type</p>
              <input
                name="assignmentType"
                value={selectedHistoryItem?.assignmentType}
                disabled
              />
            </div>
            <div className="modify-item">
              <p>Location</p>
              <input
                name="location"
                value={selectedHistoryItem?.location}
                disabled
              />
            </div>
            <div className="fh-flex-container">
              <div className="modify-item">
                <p>Complexity</p>
                <input
                  name="complexity"
                  value={selectedHistoryItem?.complexity}
                  disabled
                />
              </div>
              <div className="modify-item">
                <p>Fuel Type</p>
                <input
                  name="fuelType"
                  value={selectedHistoryItem?.fuelType}
                  disabled
                />
              </div>

              <div className="modify-item">
                <p>Fire Size</p>
                <input
                  name="fireSize"
                  value={selectedHistoryItem?.fireSize}
                  disabled
                />
              </div>
            </div>
            <div className="fh-flex-container">
              <div className="modify-item">
                <p>Shifts</p>
                <input
                  name="shifts"
                  value={selectedHistoryItem?.shifts}
                  type="number"
                  disabled
                  required
                />
              </div>

              <div className="modify-item">
                <p>Hotline Shifts</p>
                <input
                  type="number"
                  min={0}
                  onWheel={handleOnWheel}
                  name="hotline"
                  value={selectedHistoryItem?.hotline}
                  onChange={(input) =>
                    onChangeEditHistoryItem("hotline", input.target.value)
                  }
                  onKeyDown={preventNegativeValueInDocs}
                  required
                />
              </div>
            </div>
            <div className="fh-flex-container">
              <div className="modify-item">
                <p>Start Date</p>
                <input
                  disabled
                  name="startDate"
                  value={
                    selectedHistoryItem?.startDate
                      ? formatDate(selectedHistoryItem?.startDate)
                      : ""
                  }
                />
              </div>
              <div className="modify-item">
                <p>End Date</p>
                <input
                  disabled
                  name="endDate"
                  value={
                    selectedHistoryItem?.endDate
                      ? formatDate(selectedHistoryItem?.endDate)
                      : ""
                  }
                />
              </div>
            </div>

            <>
              <div className="button-holder button-holder--right">
                <button
                  disabled={false}
                  className="button grey"
                  onClick={handleCloseEditModal}
                >
                  Cancel
                </button>
                <button className="button" type="submit" disabled={isLoading}>
                  Update
                </button>
              </div>
            </>
          </div>
        </form>
      </Modal>
      <table className="mobile_hide report_list_width_full long__report tab_hide expiry_report">
        <tr className="report_list_table_row">
          <th>
            <div className="report_table_header">
              <span>Employee</span>
            </div>
          </th>
          <th>
            <div className="report_table_header">
              <span>Type</span>
            </div>
          </th>
          <th>
            <div className="report_table_header">
              <span>Event Name</span>
            </div>
          </th>
          <th>
            <div className="report_table_header">
              <span>Location</span>
            </div>
          </th>
          <th>
            <div className="report_table_header">
              <span>Complexity (1,2,3,4,5)</span>
            </div>
          </th>
          <th>
            <div className="report_table_header">
              <span>Fuel Type</span>
            </div>
          </th>
          <th>
            <div className="report_table_header">
              <span>Fire Size</span>
            </div>
          </th>
          <th>
            <div className="report_table_header">
              <span>Start Date</span>
            </div>
          </th>
          <th>
            <div className="report_table_header">
              <span>End Date</span>
            </div>
          </th>
          <th>
            <div className="report_table_header">
              <span>Shifts</span>
            </div>
          </th>
          <th>
            <div className="report_table_header">
              <span>Hotline Shifts</span>
            </div>
          </th>
          {permList.includes("Edit Assignment History") && (
            <th>
              <div className="report_table_header" />
            </th>
          )}
          {/* <th>
            <div className="report_table_header" />
          </th> */}
        </tr>
        <tbody className="report_list_table_tbody">
          {isLoading ? (
            <tr>
              <td colSpan={10}>
                <div className="dashboard_expiration_date_no_data">
                  Loading..
                </div>
              </td>
            </tr>
          ) : data?.length ? (
            data.map((item, index) => (
              <tr key={index}>
                <td>{`${item?.first_name ?? ""} ${item?.last_name}`}</td>
                <td>{item?.assignment_type ?? "-"}</td>
                <td>{item?.incident_name ?? "-"}</td>
                <td>{item?.location ?? "-"}</td>
                <td>{item?.complexity ?? "-"}</td>
                <td>{item?.fuel_type ?? "-"}</td>
                <td>{item?.fire_size ?? "-"}</td>
                <td>{formatDate(item?.date_on) ?? "-"}</td>
                <td>{formatDate(item?.date_off) ?? "-"}</td>
                <td>{item?.shifts ?? "-"}</td>
                <td>{item?.hotline_shifts ?? "-"}</td>
                {permList.includes("Edit Assignment History") && (
                  <td
                    onClick={() => onClickSelectHistoryItem(item)}
                    className="edit-button-fire-history"
                  >
                    Edit
                  </td>
                )}

                {/* <td>
                  <div>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setDeleteConfirmModal(true);
                        setItemToDelete(item);
                      }}
                    >
                      <img
                        src={deleteIcon}
                        alt="delete document"
                        style={{ width: "15px" }}
                      />
                    </span>
                  </div>
                </td> */}
              </tr>
            ))
          ) : (
            !isLoading && (
              <tr>
                <td colSpan={10}>
                  <div className="dashboard_expiration_date_no_data">
                    No Data Found
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>

      {data?.length && !isLoading ? (
        <>
          <FireHistoryReportWithEmployeesMobileView
            data={data}
            sortArrowStyle={sortArrowStyle}
            sortList={sortList}
            onDelete={(item) => {
              setDeleteConfirmModal(true);
              setItemToDelete(item);
            }}
            onClickSelectHistoryItem={(item) => onClickSelectHistoryItem(item)}
          />
          {data?.length ? (
            <div className="fs-pagination-wrapper-outer" key={7}>
              <div className="customised-pagination right-align">
                <ReactPaginate
                  previousLabel={""}
                  nextLabel={""}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={fireHistoryReport?.last_page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(data) => handlePageChange(data)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          ) : null}
        </>
      ) : (
        !isLoading && (
          <div className="dashboard_expiration_date_no_data for_mobile_history_list">
            No Data Found
          </div>
        )
      )}
      <ReactModal
        isOpen={deleteConfirmModal}
        onClose={() => setDeleteConfirmModal(false)}
      >
        <div id="statusPopup" className="popupModal">
          <div className="modal-head">
            <h4 className="modal-title">Confirm Action</h4>
          </div>
          <div className="modal-body">
            <p>{`Are you sure you want to delete ${
              itemToDelete?.first_name ?? ""
            } ${itemToDelete?.last_name} ?`}</p>
            <div className="resource_order_form_delete_modal_action">
              <button
                className="button grey"
                onClick={() => setDeleteConfirmModal(false)}
              >
                Cancel
              </button>
              <button
                name="save"
                className="button"
                onClick={() => {
                  handleDeleteItem(itemToDelete);
                  setDeleteConfirmModal(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default FireHistoryReportWithEmployees;

const FireHistoryReportWithEmployeesMobileView = ({
  data,
  sortList,
  sortArrowStyle,
  onDelete,
  onClickSelectHistoryItem,
}) => {
  const {
    assignmentTypeClass,
    incidentName,
    locationClass,
    fireSizeClass,
    fuelTypeClass,
    dateOffClass,
    dateOnClass,
    complexityClass,
  } = sortArrowStyle;

  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list tab_show">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div className="list_item">
              <strong>Employee</strong>
            </div>
            <div className="list_item">{`${item?.first_name ?? ""} ${
              item?.last_name
            }`}</div>
          </div>
          <div className="status_item">
            <div className="list_item">
              <strong>Type</strong>
            </div>
            <div className="list_item">{item?.assignment_type ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item">
              <strong>Event Name</strong>
            </div>
            <div className="list_item">{item?.incident_name ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item">
              <strong>Location</strong>
            </div>
            <div className="list_item">{item?.location ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item">
              <strong>Complexity (1,2,3,4,5)</strong>
            </div>
            <div className="list_item">{item?.complexity ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item">
              <strong>Fuel Type</strong>
            </div>
            <div className="list_item">{item?.fuel_type ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item">
              <strong>Fire Size</strong>
            </div>
            <div className="list_item">{item?.fire_size ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item">
              <strong>Start Date</strong>
            </div>
            <div className="list_item">{formatDate(item?.date_on) ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item">
              <strong>End Date</strong>
            </div>
            <div className="list_item">{formatDate(item?.date_off) ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item">
              <strong>Shifts</strong>
            </div>
            <div className="list_item">{item?.shifts ?? "-"}</div>
          </div>
          {/* <div className="status_item">
            <div className="list_item">
              <div>
                <span  className="edit-button-fire-history"
                onClick={() => onClickSelectHistoryItem(item)}>Edit</span>
              </div>
            </div>
            <div className="list_item">
              <div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => onDelete(item)}
                >
                  <img
                    src={deleteIcon}
                    alt="delete document"
                    style={{ width: "15px" }}
                  />
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  ));
};
