import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { config } from "../_helpers";
// import { applicantsActions } from '../_actions';
import { manifestActions } from "../_actions";
import "../assets/styles/resource.css";
import "./DragDropTouch.js";
import { AlertPopup } from "../_components/AlertPopup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactModal from "react-modal";
import DatePicker from "react-datepicker";
import {
  getStandardCurrentDatePST,
  formatStandardDate,
} from "_helpers/helpers";
import moment from "moment";
import { match } from "core-js/fn/symbol";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    width: "100%",
    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : 'grey',

    background: "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250,
  background: "transparent",
});

const currentDate = getStandardCurrentDatePST();

class VehicleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resource_id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : null,
      vehicles: [],
      originalVehicles: [],
      h1: this.props.edit ? "Edit Manifest" : "Create Manifest",
      alertPop: false,
      items: "",
      selected: "",
      type: this.props.type,
      displayEffectiveDatePopup: false,
      assignedManifest: false,
      assignedDateOn: null,
      assignedDateOff: null,
      isLoading: true,
    };
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.previous = this.previous.bind(this);
    this.finalize = this.finalize.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.effectiveDatePopup = this.effectiveDatePopup.bind(this);
    this.effectiveDatePopupCloseModal =
      this.effectiveDatePopupCloseModal.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  id2List = {
    droppable: "items",
    droppable2: "selected",
  };

  finalize() {
    if (this.state.items.length > 0 || this.state.type == 5) {
      var all = this.state.items;
      this.state.selected.map((item, index) => {
        all.push(item);
        return all;
      });
      this.props.next(all);
      this.props.storeAssignedManifest(this.state.assignedManifest);
      this.props.storeAssignedDateOn(this.state.assignedDateOn);
      this.props.storeAssignedDateOff(this.state.assignedDateOff);
      this.props.storeOriginalVehicles(this.state.originalVehicles);
    } else {
      // alert("Select at least 1 vehicle")
      this.setState({
        alertPop: true,
        alertHead: "Error",
        alertMsg: "Select at least 1 vehicle",
        alertFunction: "",
      });
    }
  }

  effectiveDatePopupCloseModal() {
    this.setState({
      displayEffectiveDatePopup: false,
    });
  }

  effectiveDatePopup() {
    let displayEffectiveDatePopup = false;

    if (this.state.assignedManifest) {
      const originalVehicles = this.state.originalVehicles;
      const items = this.state.items; // oncrew
      const selected = this.state.selected; // offcrew

      // Create a map of original crews for easy access
      const originalMap = new Map(
        originalVehicles.map((vehicle) => [vehicle.id, vehicle])
      );

      // Process items (oncrew)
      const modifiedItems = items.map((vehicle) => {
        const originalVehicle = originalMap.get(vehicle.id);
        const require_date_on =
          originalVehicle && originalVehicle.category === "selected"
            ? false
            : true; // True if the vehicle is offcrew now
        let date_on = null;
        if (require_date_on) {
          displayEffectiveDatePopup = true;
          if (this.state.assignedDateOff) {
            date_on = vehicle.date_on
              ? vehicle.date_on
              : currentDate >= this.state.assignedDateOn &&
                currentDate <= this.state.assignedDateOff
              ? currentDate
              : this.state.assignedDateOn;
          } else {
            date_on = vehicle.date_on
              ? vehicle.date_on
              : currentDate >= this.state.assignedDateOn
              ? currentDate
              : this.state.assignedDateOn;
          }
        }
        return {
          ...vehicle,
          require_date_on,
          date_on,
          require_date_off: false,
          date_off: null,
        }; // Spread original vehicle properties and add require_date_off
      });

      // Process selected (offcrew)
      const modifiedSelected = selected.map((vehicle) => {
        const originalVehicle = originalMap.get(vehicle.id);
        const require_date_off =
          originalVehicle && originalVehicle.category === "leftout"
            ? false
            : true; // True if the vehicle is oncrew now
        let date_off = null;
        if (require_date_off) {
          displayEffectiveDatePopup = true;
          if (this.state.assignedDateOff) {
            date_off = vehicle.date_off
              ? vehicle.date_off
              : currentDate >= this.state.assignedDateOn &&
                currentDate <= this.state.assignedDateOff
              ? currentDate
              : vehicle.assignment_date_on;
          } else {
            date_off = vehicle.date_off
              ? vehicle.date_off
              : currentDate >= this.state.assignedDateOn
              ? currentDate
              : vehicle.assignment_date_on;
          }
        }
        return {
          ...vehicle,
          require_date_off,
          date_off,
          require_date_on: false,
          date_on: null,
        }; // Spread original vehicle properties and add require_date_on
      });

      // Update state with modified lists
      this.setState({
        items: modifiedItems,
        selected: modifiedSelected,
        displayEffectiveDatePopup: displayEffectiveDatePopup,
      });
    }

    if (!displayEffectiveDatePopup) {
      this.finalize();
    }
  }

  handleDateChange = (date, vehicle, listType) => {
    this.setState((prevState) => {
      const key = listType == "items" ? "date_on" : "date_off";
      let defaultDate = currentDate;

      if (key == "date_off") {
        defaultDate =
          vehicle.assignment_date_on &&
          currentDate >= vehicle.assignment_date_on
            ? currentDate
            : currentDate >= this.state.assignedDateOn
            ? currentDate
            : vehicle.assignment_date_on || this.state.assignedDateOn;
      }

      // Determine if we’re updating items or selected
      const updatedList = prevState[listType].map((item) =>
        item.id === vehicle.id
          ? { ...item, [key]: date ? formatStandardDate(date) : defaultDate }
          : item
      );

      return {
        [listType]: updatedList,
      };
    });
  };

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  previous() {
    this.props.previous(this.props.savedCrews);
  }

  goBack() {
    this.props.cancel(this.state.resource_id);
  }

  modifyAssignment = (item, assignmentStatus) => {
    this.setState((prevState) => {
      let updatedItems, updatedSelected;

      if (assignmentStatus) {
        item.require_date_on = true;
        item.date_on = currentDate;
        item.require_date_off = false;
        item.date_off = null;

        // Check if the item's hash matches any hash in savedCrews
        const matchedCrew = this.props.savedCrews.find(
          (crew) => crew.hash === item.hash
        );

        // If a matching crew is found, check if the category is "oncrew"
        if (matchedCrew && matchedCrew.category === "oncrew") {
          // Add to `items` if not present and remove from `selected`
          const existsInItems = prevState.items.some(
            (existingItem) => existingItem.id === item.id
          );
          updatedItems = existsInItems
            ? prevState.items
            : [...prevState.items, item];
          updatedSelected = prevState.selected.filter(
            (existingItem) => existingItem.id !== item.id
          );
        } else {
          // Alert if the crew is not "oncrew" or no match is found
          let errorMsg =
            "The driver of this vehicle is not present in the manifest.";
          if (matchedCrew) {
            errorMsg = `Please assign the driver of this vehicle, ${
              matchedCrew.first_name + " " + matchedCrew.last_name
            }, to the manifest to select it.`;
          }
          toast.error(errorMsg);
          return prevState; // No changes if assignment is not allowed
        }
      } else {
        item.require_date_on = false;
        item.date_on = null;
        item.require_date_off = true;
        item.date_off = null;

        // Add to `selected` if not present and remove from `items`
        const existsInSelected = prevState.selected.some(
          (existingItem) => existingItem.id === item.id
        );
        updatedSelected = existsInSelected
          ? prevState.selected
          : [...prevState.selected, item];
        updatedItems = prevState.items.filter(
          (existingItem) => existingItem.id !== item.id
        );
      }

      // Update categories within the new `selected` and `items` arrays
      updatedSelected = updatedSelected.map((t) => ({
        ...t,
        category: "leftout",
      }));
      updatedItems = updatedItems.map((t) => ({ ...t, category: "selected" }));

      return {
        selected: updatedSelected,
        items: updatedItems,
      };
    });
  };

  componentDidMount() {
    var selectedList = [],
      leftoutList = [];
    this.setState({
      assignedManifest: this.props.assignedManifest,
      assignedDateOn: this.props.assignedDateOn,
      assignedDateOff: this.props.assignedDateOff,
      originalVehicles: this.props.originalVehicles,
    });
    if (!this.props.edit) {
      if (!this.props.prev) {
        let data = new FormData();
        data.append("resource_id", this.state.resource_id);
        this.props.dispatch(manifestActions.getVehicles(data));
      } else {
        if (this.props.SelVehicles) {
          this.props.SelVehicles.forEach((vehicle) => {
            // Check if the vehicle hash matches any hash in savedCrews
            const matchedCrew = this.props.savedCrews.find(
              (crew) => crew.hash === vehicle.hash
            );
            // Check if the matchedCrew is "oncrew"
            if (
              matchedCrew &&
              matchedCrew.category === "oncrew" &&
              vehicle.category == "selected"
            ) {
              selectedList.push(vehicle);
            } else {
              vehicle.category = "leftout";
              leftoutList.push(vehicle);
            }
          });
          this.setState({
            items: selectedList,
            selected: leftoutList,
            isLoading: false,
          });
        }
      }
    } else {
      if (this.props.prev) {
        if (this.props.SelVehicles) {
          this.props.SelVehicles.forEach((vehicle) => {
            const matchedCrew = this.props.savedCrews.find(
              (crew) => crew.hash === vehicle.hash
            );
            // Check if the matchedCrew is "oncrew"
            if (
              matchedCrew &&
              matchedCrew.category === "oncrew" &&
              vehicle.category == "selected"
            ) {
              selectedList.push(vehicle);
            } else {
              vehicle.category = "leftout";
              leftoutList.push(vehicle);
            }
          });
          this.setState({
            items: selectedList,
            selected: leftoutList,
            isLoading: false,
          });
        }
      } else {
        let data = new FormData();
        data.append("resource_id", this.state.resource_id);
        data.append("manifest_id", this.props.manifest_id);
        this.props.dispatch(manifestActions.getVehicles(data));
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { vehicles } = nextProps;
    var selectedList = [],
      leftoutList = [];
    if (vehicles.vehicleList) {
      const { data, additional_data } = vehicles.vehicleList;

      this.setState({
        originalVehicles: data,
        assignedManifest: additional_data.is_manifest_assigned,
        assignedDateOn: additional_data.date_on,
        assignedDateOff: additional_data.date_off,
      });

      if (data.length > 0 || this.state.type == 5) {
        data.forEach((vehicle) => {
          const matchedCrew = this.props.savedCrews.find(
            (crew) => crew.hash === vehicle.hash
          );
          if (matchedCrew) {
            // Check if the matchedCrew is "oncrew"
            if (
              matchedCrew.category === "oncrew" &&
              vehicle.category == "selected"
            ) {
              selectedList.push(vehicle);
            } else {
              vehicle.category = "leftout";
              leftoutList.push(vehicle);
            }
          } else {
            leftoutList.push(vehicle);
          }
        });
        this.setState({
          items: selectedList,
          selected: leftoutList,
          isLoading: false,
        });
      } else {
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: "Assign drivers to vehicle",
          alertFunction: this.goBack.bind(this),
        });
      }
    }
  }

  onDragStart(ev, id) {
    ev.dataTransfer.setData("id", id);
  }

  onTouchStart(ev, id) {
    this.setState({ touch_id: id });
  }

  onDragOver(ev) {
    ev.preventDefault();
  }

  onTouchMove(ev) {
    // ev.preventDefault();
  }

  onDrop(ev, cat) {
    let id = ev.dataTransfer.getData("id");
    let vehicles = this.state.vehicles.filter((vehicle) => {
      if (vehicle.id == id) {
        vehicle.category = cat;
      }
      return vehicle;
    });
    this.setState({ ...this.state, vehicles });
  }

  onTouchEnd(ev, cat) {
    let id = this.state.touch_id;
    let crews = this.state.crews.filter((crew) => {
      if (crew.hash == id) {
        crew.category = cat;
      }
      return crew;
    });
    this.setState({ ...this.state, crews });
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  getList = (id) => this.state[this.id2List[id]];

  onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === "droppable2") {
        state = { selected: items };
      }
      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        items: result.droppable,
        selected: result.droppable2,
      });
    }
    this.state.selected.forEach((t) => {
      t.category = "leftout";
    });
    this.state.items.forEach((t) => {
      t.category = "selected";
    });
  };

  render() {
    return (
      <>
        <ReactModal
          isOpen={this.state.displayEffectiveDatePopup}
          onClose={this.effectiveDatePopupCloseModal.bind(this)}
        >
          <div id="statusPopup" className="resources-popup fr-resources-popup">
            <div className="modal-head">
              <h4 className="modal-title">Confirm Effective Date</h4>
            </div>
            <div className="modal-body">
              <p>
                Please select the effective date of the vehicle's addition or
                removal from the crew.
              </p>
              {(() => {
                let showOncrewTitle = true;
                let showOffcrewTitle = true;

                return (
                  <>
                    {/* Loop through items (oncrew members) */}
                    {this.state.items &&
                      this.state.items.map((vehicle) =>
                        vehicle.require_date_on ? (
                          <React.Fragment key={`item-${vehicle.id}`}>
                            {showOncrewTitle && (
                              <h6>
                                Effective Dates for Addition of Vehicles to the
                                Manifest
                              </h6>
                            )}
                            {
                              (showOncrewTitle = false) /* Set flag to false after first iteration */
                            }
                            <div className="user-date-input">
                              <figure>
                                <img
                                  src={
                                    vehicle.pic
                                      ? config.s3BucketUrl + vehicle.pic
                                      : config.apiUrl + "/" + config.altImg
                                  }
                                  alt={vehicle.last_name}
                                  onError={this.onImageError.bind(this)}
                                />
                              </figure>
                              <span>{vehicle.name}</span>
                              <DatePicker
                                type="date"
                                required
                                id={`date_on_${vehicle.id}`}
                                placeholderText="Date On"
                                name={`date_on_${vehicle.id}`}
                                selected={
                                  vehicle.date_on
                                    ? moment(vehicle.date_on)
                                    : null
                                }
                                onChange={(date) =>
                                  this.handleDateChange(date, vehicle, "items")
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                                minDate={
                                  this.state.assignedDateOn
                                    ? moment(this.state.assignedDateOn)
                                    : null
                                }
                                maxDate={
                                  this.state.assignedDateOff
                                    ? moment(this.state.assignedDateOff)
                                    : null
                                }
                                onKeyDown={(e) => e.preventDefault()}
                              />
                            </div>
                          </React.Fragment>
                        ) : null
                      )}

                    {/* Loop through selected (offcrew members) */}
                    {this.state.selected &&
                      this.state.selected.map((vehicle) =>
                        vehicle.require_date_off ? (
                          <React.Fragment key={`selected-${vehicle.id}`}>
                            {showOffcrewTitle && (
                              <h5>
                                Effective Dates for Removal of Vehicles from the
                                Manifest
                              </h5>
                            )}
                            {
                              (showOffcrewTitle = false) /* Set flag to false after first iteration */
                            }
                            <div className="user-date-input">
                              <figure>
                                <img
                                  src={
                                    vehicle.pic
                                      ? config.s3BucketUrl + vehicle.pic
                                      : config.apiUrl + "/" + config.altImg
                                  }
                                  alt={vehicle.last_name}
                                  onError={this.onImageError.bind(this)}
                                />
                              </figure>
                              <span>{vehicle.name}</span>
                              <DatePicker
                                type="date"
                                required
                                id={`date_off_${vehicle.id}`}
                                placeholderText="Date Off"
                                name={`date_off_${vehicle.id}`}
                                selected={
                                  vehicle.date_off
                                    ? moment(vehicle.date_off)
                                    : null
                                }
                                onChange={(date) =>
                                  this.handleDateChange(
                                    date,
                                    vehicle,
                                    "selected"
                                  )
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                                minDate={
                                  vehicle.assignment_date_on
                                    ? moment(vehicle.assignment_date_on)
                                    : this.state.assignedDateOn
                                    ? moment(this.state.assignedDateOn)
                                    : null
                                }
                                maxDate={
                                  this.state.assignedDateOff
                                    ? moment(this.state.assignedDateOff)
                                    : null
                                }
                                onKeyDown={(e) => e.preventDefault()}
                              />
                            </div>
                          </React.Fragment>
                        ) : null
                      )}
                  </>
                );
              })()}
              <br />
            </div>
            <div class="btn_list">
              <button
                className="button grey"
                onClick={() => this.effectiveDatePopupCloseModal()}
              >
                Cancel
              </button>
              <button className="button" onClick={() => this.finalize()}>
                Submit
              </button>
            </div>
          </div>
        </ReactModal>
        <div className="applicants_container applicant_listing vehicle_manifest">
          <div className="breadcrumbs">
            <Link to={"/resources"} className="green">
              Resources
            </Link>{" "}
            / <span onClick={this.goBack.bind(this)}>Details</span> /{" "}
            {this.state.h1}
          </div>
          <div className="page_title float">
            <h1>{this.state.h1}</h1>
          </div>
          <div className="manifest_type">
            <div className="inner_head">
              <h3>Select Vehicles</h3>
            </div>

            <div className="content_div inner_container ">
              <div className="sort_crew_div">
                <div className="drag_sort_wrap">
                  <div>
                    <div className="row-flex">
                      <div className="heading-section task-header">
                        Selected Vehicles
                      </div>
                      <div className="heading-section task-header hide-mobile">
                        Left Out Vehicles
                      </div>
                    </div>
                    {/* <div className="__drag-section">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            className="selected drag_manifest_box dragConList"
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {this.state.items &&
                              this.state.items.map((item, index) => (
                                <Draggable
                                  key={index}
                                  draggableId={index}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="draggable"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <span className="pos_hold">
                                        <b>{index + 1}</b>
                                      </span>
                                      <img
                                        src={
                                          item.pic
                                            ? config.s3BucketUrl + item.pic
                                            : config.apiUrl +
                                              "/" +
                                              config.altImg
                                        }
                                        alt={item.last_name}
                                        onError={this.onImageError.bind(this)}
                                      />
                                      <span>{item.name}</span>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      <Droppable droppableId="droppable2">
                        {(provided, snapshot) => (
                          <div
                            className="droppable droppable drag_manifest_box dragConList"
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {this.state.selected &&
                              this.state.selected.map((item, index) => (
                                <Draggable
                                  key={index + "jj"}
                                  draggableId={index + "jj"}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="draggable"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <span className="pos_hold"></span>
                                      <img
                                        src={
                                          item.pic
                                            ? config.s3BucketUrl + item.pic
                                            : config.apiUrl +
                                              "/" +
                                              config.altImg
                                        }
                                        alt={item.last_name}
                                        onError={this.onImageError.bind(this)}
                                      />
                                      <span>{item.name}</span>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div> */}
                    <div className="ft sort-crew">
                      <div className="sort-crew__on-crew">
                        <ul role="list">
                          {this.state.items &&
                            this.state.items.map((item, index) => (
                              <li role="listitem">
                                <div className="sort-crew__card">
                                  <article>
                                    <span className="sort-crew__number">
                                      {index + 1}
                                    </span>
                                    <figure>
                                      <img
                                        src={
                                          item.pic
                                            ? config.s3BucketUrl + item.pic
                                            : config.apiUrl +
                                              "/" +
                                              config.altImg
                                        }
                                        alt={item.last_name}
                                        onError={this.onImageError.bind(this)}
                                      />
                                    </figure>
                                    <span className="sort-crew__name">
                                      {item.name}
                                    </span>
                                  </article>
                                  <footer>
                                    <button
                                      onClick={this.modifyAssignment.bind(
                                        this,
                                        item,
                                        false
                                      )}
                                      className="sort-crew__btn button grey"
                                    >
                                      unassign
                                    </button>
                                  </footer>
                                </div>
                              </li>
                            ))}
                          {!this.state.isLoading &&
                            !this.state.items?.length &&
                            "No Vehicles"}
                        </ul>
                      </div>
                      <div className="sort-crew__off-crew">
                        <div className="row-flex hide-desk">
                          <div className="heading-section task-header">
                            Left Out Vehicles
                          </div>
                        </div>
                        <ul role="list">
                          {this.state.selected &&
                            this.state.selected.map((item, index) => (
                              <li role="listitem">
                                <div className="sort-crew__card">
                                  <article>
                                    <span className="sort-crew__number">
                                      {index + 1}
                                    </span>
                                    <figure>
                                      <img
                                        src={
                                          item.pic
                                            ? config.s3BucketUrl + item.pic
                                            : config.apiUrl +
                                              "/" +
                                              config.altImg
                                        }
                                        alt={item.last_name}
                                        onError={this.onImageError.bind(this)}
                                      />
                                    </figure>
                                    <span className="sort-crew__name">
                                      {item.name}
                                    </span>
                                  </article>
                                  <footer>
                                    <button
                                      onClick={this.modifyAssignment.bind(
                                        this,
                                        item,
                                        true
                                      )}
                                      className="sort-crew__btn button"
                                    >
                                      assign
                                    </button>
                                  </footer>
                                </div>
                              </li>
                            ))}
                          {!this.state.isLoading &&
                            !this.state.selected?.length &&
                            "No Vehicles"}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearFix"></div>
                <div className="btn_list">
                  <button
                    className="button grey"
                    onClick={this.previous.bind(this)}
                  >
                    Previous
                  </button>
                  <button
                    className="button"
                    onClick={() => this.effectiveDatePopup()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          {this.state.alertPop && (
            <AlertPopup
              head={this.state.alertHead}
              message={this.state.alertMsg}
              viewOpen="true"
              alertFunction={this.state.alertFunction}
              close={this.closeAlert.bind(this)}
            />
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { vehicles } = state;
  return {
    vehicles,
  };
}

const connectedManifest = withRouter(connect(mapStateToProps)(VehicleList));
export { connectedManifest as VehicleList };
