import { resourceConstants } from "../_constants";
import { resourceService } from "../_services";

export const resourceActions = {
  getAll,
  getVerificationDetails,
  getAllVehicles,
  searchResource,
  deleteVehicle,
  searchDriver,
  getVehicleDetails,
  getResourceVehicles,
  removeResource,
  employeeList,
  archivedEmployees,
  unarchiveEmployee,
  unarchiveResource,
  getResourceTypes,
  getResourceDetails,
  resourcePosition,
  universalSearch,
  employeeEmailList,
  assignStaffList,
  assignStaff,
  archivedResources,
  getProjects,
  deleteResourceImageById,
  resourceOrderEmailEmployees,
  updateROResources,
};

function getProjects() {
  return (dispatch) => {
    dispatch(request());
    resourceService.getProjects().then(
      (projects) => dispatch(success(projects.data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.GETPROJECTS_REQUEST };
  }
  function success(projects) {
    return { type: resourceConstants.GETPROJECTS_SUCCESS, projects };
  }
  function failure(error) {
    return { type: resourceConstants.GETPROJECTS_FAILURE, error };
  }
}

function getAll(stats = "") {
  return (dispatch) => {
    dispatch(request());

    resourceService.getAll(stats).then(
      (resource) => dispatch(success(resource)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.GETALL_REQUEST };
  }
  function success(resource) {
    return { type: resourceConstants.GETALL_SUCCESS, resource };
  }
  function failure(error) {
    return { type: resourceConstants.GETALL_FAILURE, error };
  }
}

function getResourceTypes() {
  return (dispatch) => {
    dispatch(request());

    resourceService.getResourceTypes().then(
      (res) => dispatch(success(res)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.GET_RESOURCE_TYPE_REQUEST };
  }
  function success(res) {
    return { type: resourceConstants.GET_RESOURCE_TYPE_SUCCESS, resourceTypes: res };
  }
  function failure(error) {
    return { type: resourceConstants.GET_RESOURCE_TYPE_FAILURE, error };
  }
}

function assignStaffList() {
  return (dispatch) => {
    dispatch(request());

    resourceService.assignStaffList().then(
      (resource) => dispatch(success(resource)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.GETALL_REQUEST };
  }
  function success(resource) {
    return { type: resourceConstants.GETALL_SUCCESS, resource };
  }
  function failure(error) {
    return { type: resourceConstants.GETALL_FAILURE, error };
  }
}

function getVerificationDetails(hash) {
  return (dispatch) => {
    dispatch(request());

    resourceService.getVerificationDetails(hash).then(
      (employee) => dispatch(success(employee)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.EMPVER_REQUEST };
  }
  function success(employee) {
    return { type: resourceConstants.EMPVER_SUCCESS, employee };
  }
  function failure(error) {
    return { type: resourceConstants.EMPVER_FAILURE, error };
  }
}

function getAllVehicles() {
  return (dispatch) => {
    dispatch(request());

    resourceService.getAllVehicles().then(
      (vehicles) => dispatch(success(vehicles)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.GETVEHICLES_REQUEST };
  }
  function success(vehicles) {
    return { type: resourceConstants.GETVEHICLES_SUCCESS, vehicles };
  }
  function failure(error) {
    return { type: resourceConstants.GETVEHICLES_FAILURE, error };
  }
}

function searchResource(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      resourceService.searchResource(data).then(
        (resource) => {
          dispatch(success(resource));
          resolve(resource);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: resourceConstants.GETALL_REQUEST };
  }
  function success(resource) {
    return { type: resourceConstants.GETALL_SUCCESS, resource };
  }
  function failure(error) {
    return { type: resourceConstants.GETALL_FAILURE, error };
  }
}

function resourcePosition(data) {
  return (dispatch) => {
    dispatch(request());

    resourceService.resourcePosition(data).then(
      (position) => dispatch(getAll()),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.SETPOS_REQUEST };
  }
  // function success(position) { return { type: resourceConstants.SETPOS_SUCCESS, position } }
  function failure(error) {
    return { type: resourceConstants.SETPOS_FAILURE, error };
  }
}

function searchDriver(param) {
  return (dispatch) => {
    dispatch(request());

    resourceService.searchDriver(param).then(
      (driver) => dispatch(success(driver)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.GETDRIVER_REQUEST };
  }
  function success(driver) {
    return { type: resourceConstants.GETDRIVER_SUCCESS, driver };
  }
  function failure(error) {
    return { type: resourceConstants.GETDRIVER_FAILURE, error };
  }
}

function deleteVehicle(id) {
  return (dispatch) => {
    dispatch(request());

    resourceService.deleteVehicle(id).then(
      (vehicles) => dispatch(getAllVehicles()),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.DELETE_REQUEST };
  }
  // function success(resource) { return { type: resourceConstants.DELETE_SUCCESS, resource } }
  function failure(error) {
    return { type: resourceConstants.DELETE_FAILURE, error };
  }
}

function getVehicleDetails(id) {
  return (dispatch) => {
    dispatch(request());

    resourceService.getVehicleDetails(id).then(
      (vehicle) => dispatch(success(vehicle)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.GETVEHICLE_REQUEST };
  }
  function success(vehicle) {
    return { type: resourceConstants.GETVEHICLE_SUCCESS, vehicle };
  }
  function failure(error) {
    return { type: resourceConstants.GETVEHICLE_FAILURE, error };
  }
}

function getResourceVehicles(id) {
  return (dispatch) => {
    dispatch(request());

    resourceService.getResourceVehicles(id).then(
      (vehicles) => dispatch(success(vehicles)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.GETVEHICLES_REQUEST };
  }
  function success(vehicles) {
    return { type: resourceConstants.GETVEHICLES_SUCCESS, vehicles };
  }
  function failure(error) {
    return { type: resourceConstants.GETVEHICLES_FAILURE, error };
  }
}

function removeResource(id) {
  return (dispatch) => {
    dispatch(request());

    resourceService.removeResource(id).then(
      (remove) => dispatch(success(remove)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.REMOVERES_REQUEST };
  }
  function success(remove) {
    return { type: resourceConstants.REMOVERES_SUCCESS, remove };
  }
  function failure(error) {
    return { type: resourceConstants.REMOVERES_FAILURE, error };
  }
}

function employeeList(data) {
    return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      resourceService.employeeList(data).then(
        (employee) => {
          dispatch(success(employee));
          resolve(employee);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: resourceConstants.GETEMPLOYEES_REQUEST };
  }
  function success(employee) {
    return { type: resourceConstants.GETEMPLOYEES_SUCCESS, employee };
  }
  function failure(error) {
    return { type: resourceConstants.GETEMPLOYEES_FAILURE, error };
  }
}

function archivedEmployees() {
  return (dispatch) => {
    dispatch(request());

    resourceService.archivedEmployees().then(
      (employee) => dispatch(success(employee)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.GETEMPLOYEES_REQUEST };
  }
  function success(employee) {
    return { type: resourceConstants.GETEMPLOYEES_SUCCESS, employee };
  }
  function failure(error) {
    return { type: resourceConstants.GETEMPLOYEES_FAILURE, error };
  }
}

function archivedResources(params) {
  return (dispatch) => {
    dispatch(request());
    resourceService.archivedResources(params).then(
      (resources) => dispatch(success(resources)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.GETARCHIVEDRESOURCES_REQUEST };
  }
  function success(resources) {
    return { type: resourceConstants.GETARCHIVEDRESOURCES_SUCCESS, resources };
  }
  function failure(error) {
    return { type: resourceConstants.GETARCHIVEDRESOURCES_FAILURE, error };
  }
}

function unarchiveEmployee(data, data2) {
  return (dispatch) => {
    dispatch(request());

    resourceService.unarchiveEmployee(data).then(
      (employee) => dispatch(employeeList(data2)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.UNARCHIVE_REQUEST };
  }
  // function success(employee) { return { type: resourceConstants.UNARCHIVE_SUCCESS, employee } }
  function failure(error) {
    return { type: resourceConstants.UNARCHIVE_FAILURE, error };
  }
}

function unarchiveResource(id, index) {
  return (dispatch) => {
    dispatch(request());
    resourceService.unarchiveResource(id).then(
      (employee) => dispatch(success(index)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.UNARCHIVE_RESOURCE_REQUEST };
  }
  function success(index) {
    return { type: resourceConstants.UNARCHIVE_RESOURCE_SUCCESS, index };
  }
  function failure(error) {
    return { type: resourceConstants.UNARCHIVE_RESOURCE_FAILURE, error };
  }
}

function getResourceDetails(id) {
  return (dispatch) => {
    dispatch(request());

    resourceService.getResourceDetails(id).then(
      (singleresource) => dispatch(success(singleresource)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.GETSINGLERESOURCE_REQUEST };
  }
  function success(singleresource) {
    return { type: resourceConstants.GETSINGLERESOURCE_SUCCESS, singleresource };
  }
  function failure(error) {
    return { type: resourceConstants.GETSINGLERESOURCE_FAILURE, error };
  }
}

function universalSearch(data) {
  return (dispatch) => {
    dispatch(request());

    resourceService.universalSearch(data).then(
      (search) => dispatch(success(search)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: resourceConstants.SEARCHUNIVERSE_REQUEST };
  }
  function success(search) {
    return { type: resourceConstants.SEARCHUNIVERSE_SUCCESS, search };
  }
  function failure(error) {
    return { type: resourceConstants.SEARCHUNIVERSE_FAILURE, error };
  }
}

function employeeEmailList(data) {
  return (dispatch) => {
    dispatch(request(data));

    return new Promise((resolve,reject)=>{
      resourceService.employeeEmailList(data).then(
        (res)=>{
          resolve(res)
          dispatch(success())
        },
        (error)=>{
          reject(error)
          dispatch(failure())
        }
      )
    })
  };

  function request() {
    return { type: resourceConstants.GETEMAILEMPLOYEE_REQUEST };
  }
  function success(employee) {
    return { type: resourceConstants.GETEMAILEMPLOYEE_SUCCESS, employee };
  }
  function failure(error) {
    return { type: resourceConstants.GETEMAILEMPLOYEE_FAILURE, error };
  }
}


function resourceOrderEmailEmployees(data) {
  return (dispatch) => {
    dispatch(request(data));

    return new Promise((resolve,reject)=>{
      resourceService.resourceOrderEmailEmployees(data).then(
        (res)=>{
          resolve(res)
          dispatch(success())
        },
        (error)=>{
          reject(error)
          dispatch(failure())
        }
      )
    })
  };

  function request() {
    return { type: resourceConstants.RESOURCE_ORDER_EMAIL_EMPLOYEES_REQUEST };
  }
  function success(data) {
    return { type: resourceConstants.RESOURCE_ORDER_EMAIL_EMPLOYEES_SUCCESS, data };
  }
  function failure(error) {
    return { type: resourceConstants.RESOURCE_ORDER_EMAIL_EMPLOYEES_FAILURE, error };
  }
}

function updateROResources(id, data) {
  return (dispatch) => {
    dispatch(request(data));

    return new Promise((resolve,reject)=>{
      resourceService.updateROResources(id, data).then(
        (res)=>{
          resolve(res)
          dispatch(success())
        },
        (error)=>{
          reject(error)
          dispatch(failure())
        }
      )
    })
  };

  function request() {
    return { type: resourceConstants.RESOURCE_ORDER_UPDATE_RESOURCES_REQUEST };
  }
  function success(data) {
    return { type: resourceConstants.RESOURCE_ORDER_UPDATE_RESOURCES_SUCCESS, data };
  }
  function failure(error) {
    return { type: resourceConstants.RESOURCE_ORDER_UPDATE_RESOURCES_FAILURE, error };
  }
}

function assignStaff(data) {
  return (dispatch) => {
    dispatch(request());

    resourceService.assignStaff(data).then(
      (user) => dispatch(assignStaffList()),
      (error) => dispatch(failure(error))
    );
  };
  function request() {
    return { type: resourceConstants.ASSIGNSTAFF_REQUEST };
  }
  // function success(user) { return { type: resourceConstants.ASSIGNSTAFF_SUCCESS, user } }
  function failure(error) {
    return { type: resourceConstants.ASSIGNSTAFF_FAILURE, error };
  }
}

function deleteResourceImageById(data){
 return  (dispatch) => {
    dispatch(request());
     return new Promise((resolve,reject)=>{
      resourceService.deleteResourceImageById(data).then(
        (res) =>{
          resolve(res)
          dispatch(success());
        } ,
        (error) =>{
          reject(error)
          dispatch(failure());
        } 
        );
    })
  }
  function request() {
    return { type: resourceConstants.DELETE_RESOURCE_IMAGE_REQUEST};
  }
  function success() {
    return { type: resourceConstants.DELETE_RESOURCE_IMAGE_SUCCESS};
  }
  function failure() {
    return { type: resourceConstants.DELETE_RESOURCE_IMAGE_FAILURE};
  }

}


 