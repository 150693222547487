import React from "react";
import { connect } from "react-redux";
import { ViewManifest } from "../Manifest/ViewManifest";
import "../assets/styles/resource.css";

class ViewManifestNotify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainHtml: "",
      step: "ViewManifest",
    };
    this.list = this.list.bind(this);
  }

  list() {
    this.props.history.push("/resources");
  }

  componentDidMount() {
    this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    var options;

    if (this.state.step === "ViewManifest") {
      options = (
        <div>
          <ViewManifest
            resourceDetails={this.props.match.params.resource}
            details={this.list.bind(this)}
            manifestType={this.props.match.params.type}
            manifest_id={this.props.match.params.id}
          />
        </div>
      );
    }

    this.setState({ mainHtml: options });
  }

  render() {
    return <div>{this.state.mainHtml}</div>;
  }
}

function mapStateToProps(state) {
  return {};
}

const connectedResourcePage = connect(mapStateToProps)(ViewManifestNotify);
export { connectedResourcePage as ViewManifestNotify };
