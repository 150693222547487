import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import NewModal from "react-responsive-modal";
import { manifestActions } from "../_actions";
import { config } from "../_helpers";
import { AlertPopup } from "../_components/AlertPopup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";
import { manifestTypeConstants } from "_constants";

class SelectCrewPosition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resource_id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : null,
      confirmation: false,
      anyUnavailableCrew: false,
      savedCrews: [],
      touch_id: "",
      h1: this.props.edit ? "Edit Manifest" : "Create Manifest",
      alertPop: false,
      items: [],
      type: this.props.type,
    };
    this.toProjectPositionSelection =
      this.toProjectPositionSelection.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.confirmationOpenModal = this.confirmationOpenModal.bind(this);
    this.confirmationCloseModal = this.confirmationCloseModal.bind(this);
    this.previous = this.previous.bind(this);
  }

  confirmationOpenModal() {
    this.setState({ confirmation: true });
  }

  confirmationCloseModal(flag = false) {
    let anyUnavailableCrew = this.state.anyUnavailableCrew;

    this.setState({
      confirmation: false,
      anyUnavailableCrew: anyUnavailableCrew,
    });
  }

  previous() {
    this.props.previous(this.getUpdatedCrews());
  }

  next() {
    if (this.state.items.some((crew) => crew.incident_pos === "")) {
        return this.setState({
            confirmation: true
        });
    }
    this.props.next(this.getUpdatedCrews());
  }

  getUpdatedCrews() {
    return this.state.savedCrews.map((crew) => {
      // Check if the current crew's id matches any id in the item list
      const matchedItem = this.state.items.find(
        (item) => item.hash === crew.hash
      );

      // If a match is found, update the crew with the matched item
      if (matchedItem) {
        return { ...crew, ...matchedItem }; // Spread operator to merge the crew and matched item
      }

      return crew; // If no match, return the crew as is
    });
  }

  onSelect(hash, event) {
    const selectedValue = event.target.value;

    this.setState((prevState) => {
      // Find the index of the item to update based on the hash
      const updatedItems = prevState.items.map((item) => {
        if (item.hash === hash) {
          return { ...item, incident_pos: selectedValue }; // Update the incident_pos
        }
        return item;
      });

      return { items: updatedItems }; // Update the state with modified items
    });
  }

  componentDidMount() {
    const { savedCrews } = this.props;
    if (savedCrews) {
      const onCrewList = [];
      savedCrews.forEach((t) => {
        if (t.category === "oncrew") {
          onCrewList.push(t);
        }
      });
      this.setState({
        items: onCrewList,
        savedCrews: savedCrews,
      });
    }
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  goBack() {
    this.props.cancel(this.state.resource_id);
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  toProjectPositionSelection() {}

  renderOptions = () => {
    switch (parseInt(this.state.type)) {
      case manifestTypeConstants.DCR:
        return (
          <>
            <option value="">--</option>
            <option value="Giss">Giss</option>
            <option value="Gisst">Gisst</option>
            <option value="Project Superindent">Project Superindent</option>
            <option value="Crew Member">Crew Member</option>
          </>
        );
      case manifestTypeConstants.PROJECT:
        return (
          <>
            <option value="">--</option>
            <option value="Crew Member">Crew Member</option>
            <option value="Project Superindent">Project Superindent</option>
            <option value="Sawyer">Sawyer</option>
            <option value="Groundsmen">Groundsmen</option>
            <option value="Saftey Officer">Saftey Officer</option>
          </>
        );
      default:
        return (
          <>
            <option value="">--</option>
            <option value="CRWB/ICT4">CRWB/ICT4</option>
            <option value="CRWB/ICT5">CRWB/ICT5</option>
            <option value="CRWB">CRWB</option>
            <option value="CRWB(T)">CRWB(T)</option>
            <option value="ENGB/ICT4">ENGB/ICT4</option>
            <option value="ENGB/ICT5">ENGB/ICT5</option>
            <option value="ENGB">ENGB</option>
            <option value="ENGB(T)">ENGB(T)</option>
            <option value="FFTI/ICT5">FFTI/ICT5</option>
            <option value="FFTI/ICT5(T)">FFTI/ICT5(T)</option>
            <option value="FFTI">FFTI</option>
            <option value="FFTI(T)">FFTI(T)</option>
            <option value="ICT5(T)">ICT5(T)</option>
            <option value="FFTII">FFTII</option>
            <option value="RXB3">RXB3</option>
            <option value="RXB3(T)">RXB3(T)</option>
            <option value="GISS">GISS</option>
            <option value="GISS(T)">GISS(T)</option>
            <option value="ENG(T)">ENG(T)</option>
          </>
        );
    }
  };

  render() {
    // var i=1, j = 1;
    return (
      <div>
        <NewModal
          id=""
          className=""
          open={this.state.confirmation}
          onClose={this.confirmationCloseModal.bind(this)}
        >
          <div id="statusPopup">
            <div className="modal-head">
              <h4 className="modal-title">Error</h4>
            </div>
            <div className="modal-body">
              <p>
                Please assign a position to all crew members.
              </p>
              <br />
              <button
                className="button"
                onClick={() => this.confirmationCloseModal(true)}
              >
                Close
              </button>
            </div>
          </div>
        </NewModal>
        <div className="applicants_container sort_your_crew">
          <div className="breadcrumbs">
            <Link to={"/resources"} className="green">
              Resources
            </Link>{" "}
            / <span onClick={this.goBack.bind(this)}>Details</span> / Select
            Crew Position
          </div>
          <div className="page_title float">
            <h1>{this.state.h1}</h1>
          </div>
          <div className="manifest_type">
            <div className="inner_head">
              <h3>Select Crew Position</h3>
            </div>
            <div className="content_div inner_container ">
              <div className="sort_crew_div">
                {/* Incident Position start */}
                <div className="ft incident-position">
                  <div className="row-flex">
                    <div className="heading-section task-header">On Crew</div>
                    <div className="heading-section task-header">
                      Incident Position
                    </div>
                  </div>
                  <div className="ft sort-crew">
                    <div className="sort-crew__on-crew">
                      <ul role="list">
                        {this.state.items &&
                          this.state.items.map((item, index) => (
                            <li role="listitem">
                              <div className={`sort-crew__card ${item.colorcode}`}>
                                <article>
                                  <span className="sort-crew__number">
                                    {index + 1}
                                  </span>
                                  <figure>
                                    <img
                                      className={
                                        item.active === "Available"
                                          ? "active"
                                          : "inactive"
                                      }
                                      src={
                                        item.profile_photo
                                          ? config.apiUrl +
                                            "/" +
                                            item.profile_photo
                                          : config.apiUrl + "/" + config.altImg
                                      }
                                      alt={item.last_name}
                                      onError={this.onImageError.bind(this)}
                                    />
                                  </figure>
                                  <span className="sort-crew__name">
                                    {item.first_name + " " + item.last_name}
                                  </span>
                                </article>
                                <div className="sort-crew__position">
                                  <select
                                    value={item.incident_pos}
                                    onChange={this.onSelect.bind(
                                      this,
                                      item.hash
                                    )}
                                  >
                                    {this.renderOptions()}
                                  </select>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Incident Position end */}
                <div className="clearFix" />
                <div className="btn_list">
                  <button
                    className="button grey"
                    onClick={this.previous.bind(this)}
                  >
                    Previous
                  </button>
                  <button className="button" onClick={this.next.bind(this)}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          {this.state.alertPop && (
            <AlertPopup
              head={this.state.alertHead}
              message={this.state.alertMsg}
              viewOpen="true"
              alertFunction={this.state.alertFunction}
              close={this.closeAlert.bind(this)}
            />
          )}
        </div>
      </div>
    );
  }
}
// ReactDOM.render(<App />, document.getElementById('root'));
// export default Drag;

function mapStateToProps(state) {
  const { crews } = state;
  return {
    crews,
  };
}

const connectedResourcePage = withRouter(
  connect(mapStateToProps)(SelectCrewPosition)
);
export { connectedResourcePage as SelectCrewPosition };
